const SEO = [
	{
		page: "home",
		description:
			"Ray Oshikawa - Cloud solution architect, full-stack web app developer",
		keywords: ["Ray", "Ray Oshikawa", "Ray O"],
	},
];

export default SEO;
